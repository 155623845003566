import React from 'react'
import "./Footer.css"

const Footer = () => {
    const year = new Date().getFullYear();
  return (
    <>
    
    <footer className="dn-footer">
    <div className="dn-container">
      <p className="dn-copyright">&copy; {year} Dieu Nathan and Guillas Kevin. </p>
      <p style={{fontSize: 10, textAlign: "center", color: "black"}}>© COPYRIGHT 2023 - Tous Droits Réservés</p>
      </div>
      </footer>
      </>
  )
}

export default Footer