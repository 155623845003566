import { Routes, Route } from "react-router-dom";
//import CoffreFort from "./Composent/Coffre";
import Footer from "./Composent/Footer";
import Navbar from "./Composent/NavBar";
import Listjdr from "./Composent/listjdr";
import Home from "./Composent/Home";
//import MusicPage from "./Composent/RIP";



function App() {

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/listjdr" element={<Listjdr />} />
        {/* <Route path="/maps" element={<Maps />} />
        <Route path="/enigme" element={<CoffreFort />} />
        <Route path="/RIP" element={<MusicPage />} /> */}
      </Routes>
      <Footer />
   </> 
  );
}

export default App;
