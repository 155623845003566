import React, { useState, useEffect } from "react";
import "./Home.css";


const Home = () => {
  return (
    <div className="container">
      
      <h1 className="title">Bienvenue sur HolySheet !</h1>
      <p className="text">Voici un exemple de texte avec des liens :</p>
      <div className="button-container">
        <a href="https://discord.gg/utfjP4A3Dw" target="_blank" rel="noreferrer">
          <button>Serveur Discord</button>
        </a>
        <a href="https://letmegooglethat.com/?q=Comment+devenir+un+bon+MJ+%3F" target="_blank" rel="noreferrer">
          <button>Secret pour devenir MJ</button>
        </a>
        <a href="https://www.youtube.com/watch?v=eBGIQ7ZuuiU&ab_channel=YouGotRickRolled" target="_blank" rel="noreferrer">
          <button>Réussir sa vie</button>
        </a>
      </div>
    </div>
  );
};










export default Home;
