import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { FaDiceD20 } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
// import { BsFillGearFill } from "react-icons/bs";
// import { GiTombstone } from "react-icons/gi";
import { AiOutlineLink } from "react-icons/ai";

const Navbar = () => {
  return (
    <>
      <div className="navbar-wrapper">
        <Link to="/" className="navbar-item">
          <span className="icon">
            <AiFillHome />
          </span>
          <span>Accueil</span>
        </Link>
        <Link to="/listjdr" className="navbar-item">
          <span className="icon">
            <FaDiceD20 />
          </span>
          <span>Liste JDR</span>
        </Link>

        {/* <Link to="/maps" className="navbar-item">
          <span className="icon">
            <AiOutlineLink />
          </span>
          <span>Liens utiles</span>
        </Link> */}

        {/* <Link to="/enigme" className="navbar-item">
          <span className="icon">
            <BsFillGearFill />
          </span>
          <span>Enigme</span>
        </Link>

        <Link to="/RIP" className="navbar-item">
          <span className="icon">
            <GiTombstone />
          </span>
          <span>RIP</span>
        </Link> */}
      </div>
      <div className="shapedividers_com-7008"></div>
    </>
  );
};

export default Navbar;
