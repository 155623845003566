import React, { useState } from "react";
import "./listjdr.css";
import TITAN from "../../images/banniereTITAN.jpg";
import ARIA from "../../images/banniereARIA.jpg";
import TLOU from "../../images/banniereTLOU.jpg";


const Listjdr = () => {
  const [selectedCategory, setSelectedCategory] = useState("");

  const projects = [
    {
      title: "titan",
      image: TITAN,
      category: "TITAN",
      link: "https://titan.holysheet.fr",
    },
    {
      title: "aria",
      image: ARIA,
      category: "ARIA",
      link: "https://aria.holysheet.fr",
    },
    {
      title: "tlou",
      image: TLOU,
      category: "TLOU",
      link: "https://tlou.holysheet.fr",
    },

  ];

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const uniqueCategories = [
    ...new Set(projects.map((project) => project.category)),
  ];

  const renderCategorySelector = () => {
    return (
      <select
        className="category-selector"
        value={selectedCategory}
        onChange={handleCategoryChange}
      >
        <option value="">↓ Jeux de role ↓</option>
        {uniqueCategories.map((category, index) => (
          <option key={index} value={category}>
            {category}
          </option>
        ))}
      </select>
    );
  };

  const filteredProjects = selectedCategory
    ? projects.filter((project) => project.category === selectedCategory)
    : projects;

  const renderProjects = () => {
    return filteredProjects.map((project, index) => (
      <div key={index}>
        <a href={project.link} target="_blank" rel="noopener noreferrer">
          <img
            src={project.image}
            alt={`image ${project.title}`}
            className="p-image"
          />
        </a>
      </div>
    ));
  };

  return (
    <div className="listjdr">
      {renderCategorySelector()}
      <div className="r-listjdr">{renderProjects()}</div>
    </div>
  );
};

export default Listjdr;